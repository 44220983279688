/*@...COLORS VARIABLES...@*/ 
$primary-color:#242a56;
$primary-bgColor:#02174E;
$secondary-color:#1B78FE;//#43BAFF;
$secondary-bgColor:#092365;
$tertiary-bgColor:#E2F1FF;
$blue-Color:#1B78FE;
$primary-bgOverlay:#0C2461;

.imageTable{
    height: 30px;
    width: 45px;    
}
.statusTable{
    width: 100px;
}
